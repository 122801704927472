import { useState, useEffect } from "react";
import useAPI from "../../../hooks/useApi";

function ImportModal({ showImportModal, setShowImportModal }: { showImportModal: boolean, setShowImportModal: (showImportModal: boolean) => void }) {
    const [url, setUrl] = useState('');
    const [importing, setImporting] = useState(false);
    const [error, setError] = useState('');
    const { createRevision, getFiles } = useAPI();
    const [selectedBaseRevision, setSelectedBaseRevision] = useState(null);

    const [gSheetIdNameMap, setGSheetIdNameMap] = useState<any>({});

    useEffect(() => {
        getFiles().then((data) => {
            var gSheetIdNameMap: any = {};
            data.forEach((file: any) => {
                gSheetIdNameMap[file?.gSheetId] = file?.fileName;
            });
            // alert("gSheetIdNameMap: " + JSON.stringify(gSheetIdNameMap));
            setGSheetIdNameMap(gSheetIdNameMap);
        });
    }, [])

    useEffect(() => {
        setError('');
    }, [url]);
    return <div className="fixed top-0 bottom-0 left-0 right-0 flex backdrop-blur-md backdrop-brightness-75">
                <div className="m-auto w-[400px] bg-white p-5 rounded-xl">
                    <div className="font-semibold flex">
                        <div>
                            Create a new revision
                        </div>
                        <div className="ml-auto">
                            <i className="fas fa-times cursor-pointer text-gray-600" onClick={()=>{
                                setShowImportModal(false);
                            }}/>
                        </div>
                    </div>

                    {/* <a href="https://docs.google.com/spreadsheets/d/1kEI83uTg_iIWyDnM9-mLWWiVZksMXwMT5KVPYqAFqN4/edit?usp=sharing" target="blank" className="text-sm text-violet-600 underline">Sample File</a> */}
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md mt-3">
                        <input type="text" className="block mx-2 flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 focus:outline-none" placeholder="New revision name" value={url} onChange={(e)=>setUrl(e.target.value)} />
                    </div>
                    <div className="mt-2 text-sm text-red-500">
                        {error}
                    </div>

                <div className="rounded-md border shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                <form className="max-w-sm mx-auto">
                <select id="countries" className=" border-gray-300 text-gray-900 text-sm block w-full py-1.5 pl-2 pr-2 rounded-md focus:ring-0" onChange={(e: any)=>{
                    if(e.target.value === 'Choose a base revision'){
                        setSelectedBaseRevision(null);
                        return;
                    }
                    setSelectedBaseRevision(e.target.value);
                }}>
                    <option selected>Choose a base revision</option>
                    {Object.keys(gSheetIdNameMap).map((gSheetId: any) => {
                        return <option value={gSheetId}>{gSheetIdNameMap[gSheetId]}</option>
                    })
                    }
                </select>
                </form>
                </div>
                

                    <button className="mt-3 bg-violet-600 disabled:bg-gray-300 text-white px-5 py-2 text-sm rounded-lg" disabled={!url || !selectedBaseRevision} onClick={async ()=>{
                        setImporting(true);
                        const {error} = await createRevision(url, selectedBaseRevision!);
                        setImporting(false);
                        if(error){
                            setError(error);
                        } else {
                            setShowImportModal(false);
                        }
                    }}>
                        {importing ? 'Creating...' : 'Create Revision'}
                    </button>
                </div>
            </div>
}


export default ImportModal;