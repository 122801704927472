import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAPI from "../../../hooks/useApi";

function FileRow({ file, setFiles }: any) {
    const [name, setName] = useState(file.name);
    const [editing, setEditing] = useState(false);
    const { deleteFile, getFiles, updateFile, downloadFile } = useAPI();

    const navigate = useNavigate();

    const saveName = async () => {
        await updateFile(file._id, { name });
        setFiles(await getFiles());
    }
    return (
        <div className="flex items-center px-4 py-2 border-b cursor-pointer hover:bg-slate-100 pl-14" onClick={() => navigate('/dashboard/file/' + file._id)} key={file._id}>
            <i className="fa-regular fa-file text-lg"></i>
            {editing && <div className="flex flex-1 my-auto">
                <input className="mx-2 my-auto px-2 flex-1 " value={name} onChange={(e) => setName(e.target.value)} onClick={(e) => {
                    e.stopPropagation();
                }} />
                <div className=" hover:bg-slate-200 rounded-full h-8 w-8 flex" onClick={(e) => e.stopPropagation()}>
                    <i className="fas fa-check m-auto hover:bg-slate-200 rounded-full" onClick={async (e) => { e.stopPropagation(); await saveName(); setEditing(false) }} />
                </div>
            </div>}
            {!editing && <div className="flex flex-1">
                <div className="mx-2 w-full my-auto">{name}</div>
                {/* <div className=" hover:bg-slate-200 rounded-full h-8 w-8 flex"> */}
                    {/* <i className="fas fa-pencil m-auto" onClick={(e) => {
                        e.stopPropagation();
                        setEditing(true)
                    }} /> */}
                {/* </div> */}
            </div>}
            {/* <div className="ml-2 hover:bg-slate-200 h-8 w-8 rounded-full  flex" onClick={async (e) => {
                e.stopPropagation();
                downloadFile(file._id, file.name);
            }}>
                <i className="fas fa-download m-auto flex"></i>
            </div> */}

            <div className="ml-2 hover:bg-slate-200 h-8 w-8 rounded-full flex" onClick={async (e) => {
                e.stopPropagation();
                await deleteFile(file._id);
                setFiles(await getFiles());
            }}>
                <i className="fas fa-trash m-auto flex"></i>
            </div>
        </div>
    )
}

export default FileRow;