import { Navigate, Route, Routes } from "react-router-dom";
import { useAuth } from "../../contexts/Auth";
import File from "./FilePage";
import { useState } from "react";
import Sidebar from "./components/Sidebar";
import Files from "./components/Files";
import Chatbot from "./Chatbot";


export default function Dashboard() {
    const { isAuthenticated } = useAuth();
    const [files, setFiles] = useState<any>();
    if (!isAuthenticated) {
        return <Navigate to="/login" />;
    }
    return (
        <Routes>
            <Route
                path="/dashboard/file/:id"
                element={<File />}
            />
            <Route
                path="/dashboard/*"
                element={<div className="flex h-screen w-screen bg-[#ecedf8]">
                    <Sidebar setFiles={setFiles} />
                    <div className="flex flex-1">
                        <Routes>
                            <Route path="/files" element={
                                <Files files={files} setFiles={setFiles} />
                            } />
                            <Route path="/chatbot" element={
                                <Chatbot />
                            } />
                        </Routes>
                    </div>
                </div>}
            />

        </Routes>
    )
}
