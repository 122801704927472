import { chartToMermaid } from "./FilePage";
import { useEffect, useState } from "react";
import useAPI from "../../hooks/useApi";
import { marked } from "marked"
import mermaid from "mermaid";
import Mermaid from "../../components/Mermaid";

function Message({message, index}: any) {
    const [html, setHtml] = useState('');

    async function generateHtml(text: string) {
        setHtml(await marked(text));
    }

    useEffect(()=>{
        generateHtml(message.text);
    }, [message.text]);

    return <div key={index} className={"rounded-2xl py-2 px-4 my-2 " + (message.from === 'bot' ? 'bg-white text-indigo-950 mr-auto' : 'bg-indigo-600 text-white ml-auto')}>
        <div className="reset-tw" dangerouslySetInnerHTML={{__html: html}}></div>
</div>
}

function OrgChartTree(
    { orgChart }: { orgChart: any }
  ) {
    useEffect(() => {
      mermaid.initialize({
        startOnLoad: true
      });
    }, [orgChart]);
    return (
      <div id="treeWrapper" className="bg-white p-4 flex h-[calc(100vh-175px)]">
      <Mermaid chart={`
      flowchart TD 
      ${chartToMermaid(orgChart)}`} />
      </div>
    );
  }

function Chatbot() {

    const [messages, setMessages] = useState([
        {
            from: 'bot',
            text: 'Hello, how can I help you today?'
        }
    ]);

    const [message, setMessage] = useState('');
    const [isGenerating, setIsGenerating] = useState(false);
    const [displayedNodes, setDisplayedNodes] = useState<string[] | null>();
    const [orgChart, setOrgChart] = useState<any>();
    const { generateChatbotResponse, getNode } = useAPI();
    async function getOrgChart(nodeIds: string[]) {
        if(nodeIds.length === 0) return;
        const nodes = await Promise.all(nodeIds.map(async (nodeId) => {
            return await getNode(nodeId);
        }));
        setOrgChart(nodes);
    }
    useEffect(() => {
        displayedNodes && getOrgChart(displayedNodes); // eslint-disable-next-line
    }, [displayedNodes]);
    


    return (
        <div className="flex-1 px-8 py-8 flex flex-col mx-auto ">
            <h1 className="text-2xl font-semibold">Corporate Simplification Chatbot</h1>
            <div className="flex flex-1 gap-8">
                <div className="flex bg-transparent mt-4 rounded-3xl p-4 pb-0 flex-col w-[50vw] mx-auto relative h-[calc(100vh-100px)] overflow-y-scroll">
                    {messages.map((message, index) => <Message message={message} index={index} />)}
                    {
                        isGenerating && <div className="rounded-2xl py-2 px-4 my-2 bg-white text-indigo-950 mr-auto">
                            <svg className="h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><circle fill="#000000" stroke="#000000" stroke-width="15" r="15" cx="40" cy="100"><animate attributeName="opacity" calcMode="spline" dur="2" values="1;0;1;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.4"></animate></circle><circle fill="#000000" stroke="#000000" stroke-width="15" r="15" cx="100" cy="100"><animate attributeName="opacity" calcMode="spline" dur="2" values="1;0;1;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.2"></animate></circle><circle fill="#000000" stroke="#000000" stroke-width="15" r="15" cx="160" cy="100"><animate attributeName="opacity" calcMode="spline" dur="2" values="1;0;1;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="0"></animate></circle></svg>
                        </div>
                    }
                    <form className="flex mt-auto sticky bottom-0 left-0 right-0 bg-[#ecedf8]">
                        <input onChange={(e)=>{
                            setMessage(e.target.value);
                        }} type="text" value={message} className="flex-1 rounded-3xl py-2 px-4 bg-white outline-none placeholder:text-violet-950 placeholder:opacity-50 placeholder:font-normal" placeholder="Message Chatbot" />
                        <button disabled={isGenerating || message.length === 0} onClick={async ()=>{
                            setMessage('');
                            setMessages([...messages, {
                                from: 'user',
                                text: message
                            }]);
                            setIsGenerating(true);
                            const msg = (await generateChatbotResponse([...messages, {
                                from: 'user',
                                text: `${message}`,
                                context: `currently visible nodes: ${displayedNodes?.join(', ')}`
                            }]))
                            setIsGenerating(false);
                            setMessages([...messages, { 
                                from: 'user',
                                text: message
                            }, {
                                from: 'bot',
                                text: msg?.content
                            }]);
                            setDisplayedNodes(msg?._nodesToShow);

                        }} className="bg-indigo-600 text-white rounded-full h-10 w-10 ml-2 disabled:bg-gray-400"><i className="fa-regular fa-paper-plane"></i></button>
                    </form>
                </div>
                {
                displayedNodes && displayedNodes.length > 0 && <div className="flex mt-4 flex-1">
                    <div className="bg-white flex-1 p-4 rounded-2xl">
                        {orgChart && <OrgChartTree orgChart={orgChart}/>}
                    </div>
                </div>
            }
            </div>
        </div>
    );
}

export default Chatbot;