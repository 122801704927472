import { useNavigate } from "react-router-dom";
import GoogleSignIn from "../components/GoogleSignIn";
import { useAuth } from "../contexts/Auth";
import { decodeToken } from "react-jwt";


export default function LoginPage(){
    const { setUser } = useAuth();
    const localStorageToken = localStorage.token;
    const navigate = useNavigate();
    try{
        const localStorageUser = decodeToken(localStorageToken);
        if((localStorageUser as any).exp * 1000 > Date.now()) {
            setUser(localStorageUser);
            navigate('/dashboard/files');
        }
    } catch(e) {
        console.log('error', e);
    }
    const handleLoginSuccess = (user: any) => {
        setUser(user);
      };

    return (
        <div className="flex h-screen w-screen">
            <div className="m-auto">
                <div className="py-8 px-4 font-semibold text-xl">corporate tree manager</div>
                <div className="flex">
                    <div className="m-auto">
                        <GoogleSignIn onLoginSuccess={handleLoginSuccess} />
                    </div>
                </div>
            </div>
        </div>
    );
}