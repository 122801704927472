import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { decodeToken } from "react-jwt";

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID as string;

interface GoogleSignInProps {
  onLoginSuccess: (user: any) => void;
}

const GoogleSignIn: React.FC<GoogleSignInProps> = ({ onLoginSuccess }) => {
  const navigate = useNavigate();

  const handleSuccess = (response: any) => {
    console.log('Login Success:', response);
    const { credential } = response;
    const myDecodedToken = decodeToken(credential);
    onLoginSuccess(myDecodedToken);
    localStorage.token = credential;
    navigate('/dashboard/files'); // Navigate to the dashboard after a successful login
  };

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <div>
        <GoogleLogin onSuccess={handleSuccess} />
      </div>
    </GoogleOAuthProvider>
  );
};

export default GoogleSignIn;
