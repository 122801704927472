import { useEffect, useState } from "react";
import { useAuth } from "../../../contexts/Auth";
import useAPI from "../../../hooks/useApi";
import FileRow from "./FileRow";


function FolderRow({name, files, setFiles}: any) {
    const [expanded, setExpanded] = useState(false);

    return <div onClick={()=>{
        setExpanded(!expanded);
    }}>
        <div className="flex items-center px-4 py-2 border-b cursor-pointer hover:bg-slate-100">
            <div className="flex h-7">
                <i className={`fa-solid fa-angle-right text-sm my-auto text-center ${expanded?'rotate-90':''} transition-all`}></i>
            </div>
            <i className="mx-2  fa-regular fa-folder text-lg"></i>
            <div className="">{name}</div>
            <a target="blank" href={`https://docs.google.com/spreadsheets/d/${files[0].gSheetId}`} className="ml-2 hover:bg-slate-200 h-8 w-8 rounded-full  flex" onClick={async (e) => {
                e.stopPropagation();
            }}>
                <i className="fas fa-up-right-from-square m-auto text-xs flex"></i>
            </a>
        </div>
        <div className="">
        {
                expanded && files?.map((file: any) => <FileRow key={file._id} file={file} setFiles={setFiles} />)
            }
        </div>
    </div>
}

function Files({ files, setFiles }: { files: any, setFiles: (files: any) => void }) {
    const { user } = useAuth();
    console.log(user);
    const { getFiles } = useAPI();
    const gSheetIdFileMap = files?.reduce((acc: any, file: any) => {
        acc[file.gSheetId] = acc[file.gSheetId] || [];
        acc[file.gSheetId].push(file);
        return acc;
    }, {}) || {};

    console.log(gSheetIdFileMap);
    console.log(gSheetIdFileMap, Object.values(gSheetIdFileMap));
    
    useEffect(() => {
        getFiles().then((data) => {
            setFiles(data);
        });
    } //eslint-disable-next-line
        , []);


    return (
        <div className="flex-1 p-8 flex flex-col">
            <div className="flex">
                <h1 className="text-2xl font-semibold">Corporate Tree Files</h1>
                <div className="ml-auto flex">
                    <img referrerPolicy="no-referrer" src={user.picture} className="rounded-full h-8 w-8 " alt="" />
                    <div className="mx-2 my-auto">
                        {user.name}
                    </div>
                </div>
            </div>

            <div className="bg-white p-6 my-4 flex-1 flex flex-col rounded-3xl h-[calc(100vh-200px)]">
                <div className="flex items-center px-4 py-2 border-b font-medium text-sm border-black">
                    <span className="mx-2">Name</span>
                </div>
                <div className="flex flex-col overflow-y-scroll">
                    {Object.values(gSheetIdFileMap)?.map((gSheet: any)=>{
                        return <FolderRow name={gSheet[0]?.fileName} files={gSheet} setFiles={setFiles}/>
                    })}
                </div>
            </div>
        </div>

    );
}

export default Files;   