import React, { useEffect } from "react";
import mermaid from "mermaid";

mermaid.initialize({
  startOnLoad: true,
  fontFamily: "Poppins",
});

export default function Mermaid({ chart, usePanZoom }: { chart: string, usePanZoom?: boolean }) {
    useEffect(() => {
        document.getElementsByClassName("mermaid")[0].removeAttribute("data-processed");
        mermaid.contentLoaded();
        setTimeout(()=>{
            if(!usePanZoom) return;
            // document.getElementById('orgChart')?.children[0]?.setAttribute('style', 'width: calc(100vw - 320px)');
            const svg = document.getElementById('orgChart')?.children[0]
            console.log('svg', svg?.tagName);
            if(svg?.tagName === 'svg') {
                svg.setAttribute('style', 'width: calc(100vw - 0px)');
                const svgPanZoom = (window as any).svgPanZoom(svg, {
                    fit: false
                });
                svgPanZoom.zoom(0.8)
            }
        }, 1000)
    }, [chart]);
    
    return <div className="mermaid flex" id="orgChart" >
        {chart}
    </div>
}
