import { useNavigate } from 'react-router-dom';
import { Node } from '../types/Node';
import { decodeToken } from 'react-jwt';

export default function useAPI() {

    const navigate = useNavigate();

    async function checkLogin() {
        const localStorageToken = localStorage.token;
        try {
            const localStorageUser = decodeToken(localStorageToken);
            if ((localStorageUser as any).exp * 1000 < Date.now()) {
                navigate('/login');
            }
        } catch (e) {
            console.log('error', e);
        }
    }

    async function createNode(
        node: Node
    ) {
        checkLogin();
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "/nodes", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                body: JSON.stringify(node)
            });
            return await response.json();
        } catch (error) {
            console.error("Error creating node: ", error);
            alert("Error creating node");
        }
    }

    async function getFile(fileId: string) {
        checkLogin();
        try {
            const fetchData = async () => {
                const response = await fetch(process.env.REACT_APP_API_URL + "/files/" + fileId, {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    }
                });
                const data = await response.json();
                return data;
            };
            return await fetchData();
        } catch (error) {
            console.error("Error fetching files: ", error);
            alert("Error fetching files");
        }
    }

    async function updateNode(
        id: string,
        updates: any
    ) {
        checkLogin();
        if (updates?.parent === "___ROOT___") {
            updates.parent = null;
        }
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "/nodes/" + id, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                body: JSON.stringify(updates)
            });
            return await response.json();
        } catch (error) {
            console.error("Error updating node: ", error);
            alert("Error updating node");
        }
    }

    async function deleteNode(
        id: string
    ) {
        checkLogin();
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "/nodes/" + id, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            });
            return await response.json();
        } catch (error) {
            console.error("Error deleting node: ", error);
            alert("Error deleting node");
        }
    }

    async function getFiles() {
        try {
            checkLogin();
            const fetchData = async () => {
                const response = await fetch(process.env.REACT_APP_API_URL + "/files", {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    }
                });
                const data = await response.json();

                return data.sort(
                    (a: any, b: any) => a.name.localeCompare(b.name)
                );
            };
            return await fetchData();
        } catch (error) {
            console.error("Error fetching files: ", error);
            alert("Error fetching files");
        }
    }

    async function deleteFile(
        id: string
    ) {
        checkLogin();

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "/files/" + id, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            });
            return await response.json();
        } catch (error) {
            console.error("Error deleting file: ", error);
            alert("Error deleting file");
        }
    }

    async function createFile() {
        try {
            checkLogin();

            const response = await fetch(process.env.REACT_APP_API_URL + "/files", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                body: JSON.stringify({
                    name: "New File"
                })
            });
            return await response.json();
        } catch (error) {
            console.error("Error creating file: ", error);
            alert("Error creating file");
        }
    }

    async function updateFile(fileId: string, updates: any) {
        try {
            checkLogin();

            const response = await fetch(process.env.REACT_APP_API_URL + "/files/" + fileId, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                body: JSON.stringify(updates)
            });
            return await response.json();
        } catch (error) {
            console.error("Error updating file: ", error);
            alert("Error updating file");
        }
    }

    async function downloadFile(fileId: string, fileName: string) {
        try {
            checkLogin();

            const response = await fetch(process.env.REACT_APP_API_URL + "/files/download/" + fileId, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            });
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = (fileName || fileId) + ".csv";
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error downloading file: ", error);
            alert("Error downloading file");
        }
    }

    async function uploadFile(json: any) {
        try {
            checkLogin();

            const response = await fetch(process.env.REACT_APP_API_URL + "/files/upload", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                body: JSON.stringify({ csv: json, name: new Date().toISOString() })
            });
            const res = await response.json();
            console.log("Upload response: ", res);
            return res.file;
        } catch (error) {
            console.error("Error uploading file: ", error);
            alert("Error uploading file");
        }
    }

    async function createRevision(newRevisionName: string, baseRevisionGSheetId: string) {
        try {
            checkLogin();

            const response = await fetch(process.env.REACT_APP_API_URL + "/revision", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                body: JSON.stringify({ newRevisionName, baseRevisionGSheetId })
            });
            const res = await response.json();
            console.log("Import response: ", res);
            return res;
        } catch (error) {
            console.error("Error importing GSheet: ", error);
            alert("Error importing GSheet");
        }
    }

    async function generateChatbotResponse(
        messages: any
    ) {
        checkLogin();

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "/chat", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                body: JSON.stringify({ messages })
            });
            const res = await response.json();
            console.log("Chatbot response: ", res);
            return res;
        } catch (error) {
            console.error("Error generating chatbot response: ", error);
            alert("Error generating chatbot response");
        }
    }

    async function getNodeDetails(nodeId: string) {
        try {
            checkLogin();

            const response = await fetch(process.env.REACT_APP_API_URL + "/nodes/" + nodeId + "/details", {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
            });
            const res = await response.json();
            console.log("Chatbot response: ", res);
            return res;
        } catch (error) {
            console.error("Error generating chatbot response: ", error);
            alert("Error generating chatbot response");
        }
    }

    async function getNode(nodeId: string) {
        try {
            checkLogin();

            const response = await fetch(process.env.REACT_APP_API_URL + "/nodes/" + nodeId, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
            });
            const res = await response.json();
            console.log("Chart response: ", res);
            return res;
        } catch (error) {
            console.error("Error generating chart: ", error);
            alert("Error generating chart");
        }
    }

    async function refetchFile(fileId: string) {
        try {
            checkLogin();
            const response = await fetch(process.env.REACT_APP_API_URL + "/files/" + fileId + "/reimport", {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            });
            return await response.json();
        } catch (error) {
            console.error("Error refetching file: ", error);
            alert("Error refetching file");
        }
    }

    return {
        createNode,
        getFile,
        updateNode,
        deleteNode,
        getFiles,
        deleteFile,
        createFile,
        updateFile,
        downloadFile,
        uploadFile,
        createRevision,
        generateChatbotResponse,
        getNodeDetails,
        getNode,
        refetchFile
    }
}